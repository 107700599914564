    export const COMBINED_WIND_SOLAR_SUFFIX: string = "?messageType=getcombinedwindsolar&returnType=json";
    export const SCHEDULED_INTERCHANGE_SUFFIX: string = "?messageType=getNSI5MISO&returnType=json";
    export const ACTUAL_INTERCHANGE_SUFFIX: string = "?messageType=getimporttotal5&returnType=json";
    export const FIRST_TIER_SCHEDULED_SUFFIX: string = "?messageType=getNSI5&returnType=json";
    export const FUEL_MIX_SUFFIX: string = "?messageType=getfuelmix&amp;returnType=json";
    export const CSAT_PAPI_SUFFIX: string = "/CsatSupplyDemand";
    export const CSAT_NET_UNCERTAINTY_SUFFIX: string = "/CsatNetUncertainty";
    export const CSAT_NEXT_DAY_SHORT_TERM_RESERVE_REQUIREMENT_SUFFIX: string = "/CsatNextDayShortTermReserveRequirement";
    export const RDT_SUFFIX: string = "?messageType=getregionaldirectionaltransfer&returnType=json";
    export const GEN_OUT_SUFFIX: string = "?messageType=getgenerationoutagesplusminusfivedays&returnType=json";
    export const REAL_TIME_TOTAL_SUFFIX: string = "?messageType=gettotalload&returnType=json";
    export const ACE_SUFFIX: string = "?messageType=getACE&returnType=json";

    export const DESCRIPTION_MESSAGE: string = "description";
    export const DOWNLOAD_MESSAGE: string = "download";
    export const POPOUT_MESSAGE: string = "popout";

    export const STR_REQ_MESSAGE: string= "STR requirement overridden to high with projected system conditions to be at Capacity Advisory or above per EOP-002";
    export const STR_REQ_FOOTER_LINK: string = "https://www.misoenergy.org/engage/committees/market-subcommittee";
    