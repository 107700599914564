import { useEffect, useState, useRef } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official'
import { useLocation } from 'react-router'

import { MisoColors } from '../colors'
import { formatNumber, formatShortDateForAxis, x48To24hr } from '../Utils/StringFormatHelpers'
import { formatTooltipTimeToHoursSinceMidnight } from '../Utils/ToolTipHelpers'
import { TwoDayChartProps, XYPoint } from '../Interfaces/Charts'
import { COMBINED_WIND_SOLAR_SUFFIX, DESCRIPTION_MESSAGE, DOWNLOAD_MESSAGE, POPOUT_MESSAGE } from '../Constants'
import VCenteredModal from '../Components/VCenteredModal'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { Button } from 'react-bootstrap'
import { Download } from '../Utils/Downloader'
import { convertSimpleDateToISOFormat } from '../Utils/DateHelpers'

interface combinedInstance { 
    ForecastHourEndingEST: string; 
    ForecastWindValue: string; 
    ActualWindValue: string; 
    ForecastSolarValue: string; 
    ActualSolarValue: string 
    ForecastDateTimeEST: Date | null | undefined;
    ActualDateTimeEST: Date | null | undefined;
}

// setup series arrays
let forecastWind: XYPoint[] = [];
let actualWind: XYPoint[] = [];
let forecastSolar: XYPoint[] = [];
let actualSolar: XYPoint[] = [];
let timeCutover: number;

// setup dates and labels
let mktDay: Date;
let nxtDayDt: Date;
let mktDayFriendly: string;
let nxtDayFriendly: string;

let intervalString: string;

function parseData(data: any, useTwoDays: boolean): void {
    mktDay = convertSimpleDateToISOFormat(data.MktDay);
    nxtDayDt = new Date(mktDay);
    nxtDayDt.setDate(nxtDayDt.getDate() + 1);
    intervalString = data.RefId;

    // friend-ify dates
    mktDayFriendly = formatShortDateForAxis(mktDay);
    nxtDayFriendly = formatShortDateForAxis(nxtDayDt);

    var hasCutover = false;
    let nextDay: boolean = false;

    actualWind = [];
    actualSolar = [];
    forecastWind = [];
    forecastSolar = [];

    data.instance.forEach((inst: combinedInstance) => {
        if(useTwoDays || !nextDay) {
            var hourEnding = parseInt(inst.ForecastHourEndingEST);
            var hourStarting = !nextDay ? hourEnding - 1 : hourEnding + 23;
            var skipForecast = inst.ActualDateTimeEST !== null && inst.ActualDateTimeEST !== undefined;
            if(!hasCutover && !skipForecast){
                hasCutover = true;
                timeCutover = hourStarting - 1;
            }
            
            // skip duplicates
            if (actualWind.find(i => i.x === hourStarting) === undefined && (actualWind.length === hourStarting)) {
                actualWind.push({
                    x: hourStarting,
                    y: (inst.ActualWindValue && skipForecast) ? parseFloat(inst.ActualWindValue) : null
                });
            }

            if (actualSolar.find(i => i.x === hourStarting) === undefined && (actualSolar.length === hourStarting)) {
                actualSolar.push({
                    x: hourStarting,
                    y: (inst.ActualSolarValue && skipForecast) ? parseFloat(inst.ActualSolarValue) : null
                });
            }

            if (forecastWind.find(i => i.x === hourStarting) === undefined && (forecastWind.length === hourStarting)) {
                forecastWind.push({
                    x: hourStarting,
                    y: (inst.ForecastWindValue) ? parseFloat(inst.ForecastWindValue) : null
                });
            }

            if (forecastSolar.find(i => i.x === hourStarting) === undefined && (forecastSolar.length === hourStarting)) {
                forecastSolar.push({
                    x: hourStarting,
                    y: (inst.ForecastSolarValue) ? parseFloat(inst.ForecastSolarValue) : null
                });
            }

            if (hourEnding === 24) {
                nextDay = true;
            }
        }
    });
}

let initialOptions: Highcharts.Options = {
    credits: {
        enabled: false
    },
    title:{
        text: ''
    },
    lang: {
        noData: "No data was received"
    },
    chart: {
        reflow: true,
        style: {
            fontFamily: 'Lato, Arial, Helvetica, sans-serif',
            whiteSpace: "wrap"
        },
    },
    plotOptions: {
        line: {
            marker: {
                enabled: false,
            },
        },
    },
    xAxis: [
        // Main axis
        {
            title: {
                text: `Hours EST`,
                style: {
                    fontWeight: 'bold',
                    color: MisoColors.black,
                },
            },
            labels: {
                formatter: x48To24hr
            }
        },
    ],
    yAxis: {
        title: {
            text: "MWs",
            style: {
                fontWeight: "bold",
            },
        },
        min: 0,
        tickInterval: 1000,
    },
    tooltip: {
        shared: true,
        formatter: function() {
            if(this.points == null) return false;
            const x = this.x as number
            return this.points.reduce(formatTooltip, '<b>' + formatTooltipTimeToHoursSinceMidnight(x, mktDay) + '</b>'); // points.reduce(format_func(prev_val, val), initial_prev_val)
        }
    },
    series: [],
    legend: {
        enabled: true,
        width: '100%',
        layout: 'horizontal',
        itemMarginBottom: 5,
        itemStyle: {
            display: "flex"
        },
        className:'w-100 d-flex justify-content-between',
        labelFormatter: function () {
            return '<div class="fs14 w-100 d-flex justify-content-between margin-btm-5"><div>' + this.name + " </div> <div class='fw-normal'> </div></div>";
        }
    },
};

function formatTooltip(s: string, point: Highcharts.TooltipFormatterContextObject): string{
    
    const y = point.y as number;

    // keep the existing tooltip, add break line
    var tooltip = s + '<br />';

    if(point.series.name === 'Real-Time Solar Generation (MW)'){
        tooltip = tooltip + '<br />'
    }
    
    // now add to it
    tooltip += '<span style="color:'+ point.color +'">\u25cf</span> '+ point.series.name +': <b>' + formatNumber(y) +' MW</b>';
    return tooltip;
}

export default function CombinedWindSolar(props: TwoDayChartProps) {
    
    const location = useLocation();
    const useTwoDays = new URLSearchParams(location.search).get('twoDays') || props.twoDays;

    const [options, setOptions] = useState(initialOptions);
    const chartRef = useRef<HighchartsReactRefObject>(null);

    const [showModal, setShowModal] = useState(false);
    const [modalBody, setModalBody] = useState<React.ReactNode>(<></>);
    const modalHeader: React.ReactNode = <span>Wind and Solar Forecast and Real-Time Generation</span>;
    NoDataToDisplay(Highcharts);
    
    useEffect(() => {
        const descriptionBody: React.ReactNode = <><span>MISO wind and solar generation forecasts are provided by a third-party vendor. The forecasts are produced based on Numerical Weather Prediction models with weather forecast services that are procured by the respective vendors. The forecast snapshots are captured at 3:45 p.m. MISO system time the day prior to the displayed day. The displayed date represents the 24 hours of the current calendar day. All points on the chart represent the hourly integrated value and are displayed with the timestamp at the hour ending.</span><br /><br /><span>*This data is for visibility purposes only. Please note: Real-Time Actual Generation 1. Reflects dispatch down impacts. 2. May include output from testing units.</span></>
        let downloadBody: React.ReactNode = <div className="download-modal">
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_DATABROKER_URL}${COMBINED_WIND_SOLAR_SUFFIX}`, "WindSolarForecastRealTime", "json")}>Download JSON</Button>
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_DATABROKER_URL}${COMBINED_WIND_SOLAR_SUFFIX}`.replace("json", "csv"), "WindSolarForecastRealTime", "csv")}>Download CSV</Button>
            <Button className="download-btn" onClick={() => Download(`${process.env.REACT_APP_DATABROKER_URL}${COMBINED_WIND_SOLAR_SUFFIX}`.replace("json", "xml"), "WindSolarForecastRealTime", "xml")}>Download XML</Button>
        </div>;
        switch (props.buttonClicked) {
            case DESCRIPTION_MESSAGE:
                setModalBody(descriptionBody);
                setShowModal(true);
                break;
            case DOWNLOAD_MESSAGE:
                setModalBody(downloadBody);
                setShowModal(true);
                break;
            case POPOUT_MESSAGE:
                const newWinddow = window.open(`/charts/cwsc?twoDays=${useTwoDays}`, '_blank', 'width=800,height=600,noopener,noreferrer');
                if (newWinddow) newWinddow.opener = null;
                break;
            default:
                break;
        }
        
    }, [props.buttonClicked, useTwoDays]);

    useEffect(() => {
        function fetchData(): void {
            if(chartRef.current === null){
                return;
            }
            const chart = chartRef.current.chart;
            chart.showLoading();

            fetch(process.env.REACT_APP_DATABROKER_URL + "" + COMBINED_WIND_SOLAR_SUFFIX)
            .then(response => {
                return response.json();
            })
            .then(data => {
                parseData(data, useTwoDays === 'true');
                setOptions({
                    xAxis: [
                        // Main axis
                        {
                            title: {
                                text: `Hours EST`,
                                style: {
                                    fontWeight: 'bold',
                                    color: MisoColors.black,
                                },
                            },
                            labels: {
                                formatter: function(this: Highcharts.AxisLabelsFormatterContextObject) {
                                    return ((this.value as number) % 24).toString();
                                }
                            },
                            tickInterval: 2,
                            plotLines: [{
                                color: '#C0C0C0', // Grey
                                width: 4,
                                value: timeCutover,
                                zIndex: 3
                            }]
                        },
                        // axis to add date to left
                        {
                            title: {
                                text: `${mktDayFriendly}`,
                                align: "low",
                                style: {
                                    fontWeight: 'bold',
                                    color: MisoColors.black,
                                },
                                offset: -30,
                            },
                            lineWidth: 0,
                        },
                        // axis to add date to right
                        {
                            title: {
                                text: `${nxtDayFriendly}`,
                                align: "high",
                                style: {
                                    fontWeight: 'bold',
                                    color: MisoColors.black,
                                },
                                offset: -30,
                            },
                            lineWidth: 0,
                        }
                    ],
                    series: [
                        {
                            name: "Real-Time Wind Generation",
                            type: "line",
                            data: actualWind,
                            color: MisoColors.misoGreen,
                        },
                        {
                            name: "Day-Ahead Wind Forecast",
                            type: "line",
                            data: forecastWind,
                            dashStyle: 'LongDash',
                            color: MisoColors.misoGreen,
                            showInLegend: false,
                            linkedTo: "Day-Ahead Wind Forecast"
                        },
                        {
                            name: "Day-Ahead Wind Forecast",
                            type: "line",
                            data: [],
                            showInLegend: true,
                            dashStyle: 'ShortDash',
                            color: MisoColors.misoGreen,
                            id: "Day-Ahead Wind Forecast"
                        },
                        {
                            name: "Real-Time Solar Generation",
                            type: "line",
                            data: actualSolar,
                            color: MisoColors.misoSolar1,
                        },
                        {
                            name: "Day-Ahead Solar Forecast",
                            type: "line",
                            data: forecastSolar,
                            dashStyle: 'LongDash',
                            color: MisoColors.misoSolar1,
                            showInLegend: false,
                            linkedTo: "Day-Ahead Solar Forecast"
                        },
                        {
                            name: "Day-Ahead Solar Forecast",
                            type: "line",
                            data: [],
                            showInLegend: true,
                            dashStyle: 'ShortDash',
                            color: MisoColors.misoSolar1,
                            id: "Day-Ahead Solar Forecast"
                        },
                    ]
                })
                chart.redraw();
            }).catch(() => {});
            chart.hideLoading();
            
            // Fixes rendering bug on iOS devices
            chart.redraw();
            chart.reflow();
        }

        fetchData();
        const FIFTEEN_MIN_MS = 1000 * 60 * 15;
        const interval = setInterval(() => fetchData(), FIFTEEN_MIN_MS)

        // return fires on unmount, prevent memory leak
        return() => clearInterval(interval);
    }, [useTwoDays]);

    return (
        <div className="chart">
            <VCenteredModal show={showModal} onHide={() => setShowModal(false)} headercontent={modalHeader} bodycontent={modalBody} />
            <div className="interval">{intervalString}</div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartRef}
            />
        </div>
    )
}
